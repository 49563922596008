import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Header from '../../components/Header/Header';
import UniversalLink from '../../components/UniversalLink/UniversalLink';
import {
  GradientContainer,
  Label404,
  Description,
  HomeButton,
} from '../../shared-styled/blocks/errorPagesBlocks';
import {
  BasePageContainer,
  ChildrenWrapper,
} from '../BasePage/BasePage.styled';
import NavigationPage from '../NavigationPage/NavigationPage';

const ERROR_CODES = {
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  UNAUTHORIZED: '403',
};

const ERROR_MESSAGES = {
  NOT_FOUND: 'Page Not Found',
  SERVER_ERROR: 'Internal Server error',
  UNAUTHORIZED: 'Login failed',
};

const NotFoundPage = () => {
  const { isNavActive } = useGlobalContext();
  const router = useRouter();
  const { case: errorCase, path } = router.query;

  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');

  useEffect(() => {
    if (!path || !path?.includes('error')) {
      setErrorMessage(ERROR_MESSAGES.NOT_FOUND);
      setErrorCode(ERROR_CODES.NOT_FOUND);
      return;
    }
    // Set custom messages based on error case in query
    switch (errorCase) {
      case '500':
        setErrorMessage(ERROR_MESSAGES.SERVER_ERROR);
        setErrorCode(ERROR_CODES.SERVER_ERROR);
        break;
      case '403_login':
        setErrorMessage(ERROR_MESSAGES.UNAUTHORIZED);
        setErrorCode(ERROR_CODES.UNAUTHORIZED);
        break;
      default:
        setErrorMessage(ERROR_MESSAGES.NOT_FOUND);
        setErrorCode(ERROR_CODES.NOT_FOUND);
        break;
    }
  }, [errorCase, path]);

  return (
    <BasePageContainer>
      <Header />
      {isNavActive ? (
        <NavigationPage isNotFoundPage={true} />
      ) : (
        <ChildrenWrapper>
          <GradientContainer>
            <Label404>{errorCode}</Label404>
            <Description>{errorMessage}</Description>
            <UniversalLink href='/'>
              <HomeButton>Go Home</HomeButton>
            </UniversalLink>
          </GradientContainer>
        </ChildrenWrapper>
      )}
    </BasePageContainer>
  );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
