import { useRouter } from 'next/router';
import { getPage, getAllPages, getMainPages } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import NotFoundPage from '../containers/NotFoundPage';
import homePageDataFetch from '../lib/homePageDataFetch';
import pressPagesDataFetch from '../lib/pressPagesDataFetch';
import { matchUrl } from '../utils/matchUrl';

const isProd = process.env.NODE_ENV === 'production';

const CatchAllPage = ({ componentName, componentProps }) => {
  const router = useRouter();

  if (!componentName) {
    return <NotFoundPage />;
  }

  const Component = LazyContainers[componentName];
  if (!Component) {
    return <h1>Component {componentName} not found</h1>;
  }

  const joinedComponentProps = {
    ...componentProps,
    componentName,
  };

  return router.isFallback ? (
    <div>Loading...</div>
  ) : (
    <Component {...joinedComponentProps} />
  );
};

// For SSR
// ...

// For SSG

export async function getStaticProps({ params, preview, previewData }) {
  params = params || {};
  let path = params.path || [];
  path = path.join('/');

  try {
    if (path === '/' || path === '') {
      const pageData = await homePageDataFetch();

      return {
        props: { ...pageData },
        revalidate: 45,
      };
    }

    if (
      process.env.IS_NEXT_EXPORT === '1' &&
      (path === 'news' || path === 'reports')
    ) {
      const pageData = await pressPagesDataFetch(path);

      return {
        props: { ...pageData },
        revalidate: 45,
      };
    }

    let subcategoriesItems = [];

    if (
      matchUrl(path.split('/')[0], [
        'armedforces',
        'weapons',
        'militant-organizations',
      ])
    ) {
      try {
        const { json: pageData } = await getPage(path.split('/')[0]);

        if (!pageData.componentProps) {
          return {
            props: { componentProps: {}, componentName: 'Page304' },
            revalidate: 45,
          };
        }

        const items = path.includes('armedforces')
          ? pageData.componentProps.regions
          : pageData.componentProps.items;

        subcategoriesItems = items.map((el) => {
          const val =
            el.path || el.url?.split('/')[2] || el.absolutePath?.split('/')[2];
          return { value: val.toLowerCase(), label: el.title };
        });
      } catch (e) {
        console.error('error name', e.name, '\n', 'error.res', e.response, e);
      }
    }

    const { json: pageData } = await getPage(path);

    if (pageData.status === 404) {
      return {
        props: { componentProps: {}, componentName: 'NotFoundPage' },
        revalidate: 45,
      };
    }

    if (!pageData.componentProps) {
      return {
        props: { componentProps: {}, componentName: 'Page304' },
        revalidate: 45,
      };
    }

    pageData.componentProps.subcategoriesItems = subcategoriesItems;
    return {
      props: { ...pageData },
      revalidate: 45,
    };
  } catch (e) {
    console.error('error name', e.name, '\n', 'error.res', e.response, e);
    return {
      props: { componentProps: {}, componentName: 'Page503' },
      revalidate: 45,
    };
  }
}

export async function getStaticPaths() {
  let htmlUrls;
  if (process.env.IS_NEXT_EXPORT === '1') {
    const { json: data } = await getAllPages(true);
    htmlUrls = data.items.map((x) => x.relativeUrl);
  } else {
    const { json: data } = await getMainPages();
    htmlUrls = data.items?.listUrls;
  }

  htmlUrls = htmlUrls?.filter((x) => x);
  htmlUrls = htmlUrls?.map((x) => x.split('/'));
  htmlUrls = htmlUrls?.map((x) => x.filter((y) => y));
  htmlUrls = htmlUrls?.filter((x) => x.length);

  const paths = htmlUrls?.map((x) => ({ params: { path: x } }));

  return {
    paths,
    fallback: 'blocking',
  };
}

export default CatchAllPage;
